import * as bankCardsTypes from '../types/bankCardsTypes';

const INITIAL_STATE = {
  loading: false,
  userId: 0,

  data: [],
  succeeded: false,
  message: '',
  errors: null
};

const bankCardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bankCardsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case bankCardsTypes.GET_BANK_CARDS:
      return {
        ...state,
        loading: false,
        userId: action.userId,
        ...action.payload
      };
    case bankCardsTypes.GET_BANK_CARDS_ERROR:
      return {
        ...state,
        loading: false
      };
    case bankCardsTypes.CHANGE_CARD_STATUS:
      return {
        ...state,
        loading: false
      };
    case bankCardsTypes.REMOVE_BANK_CARD:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default bankCardsReducer;
