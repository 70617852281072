export const systemRoles = {
  Customer: 1,
  SystemAdministrator: 2,
  BusinessAdministrator: 3,
  Operations: 4,
  Compliance: 5,
  Sales: 6,
  Finance: 7,
  Audit: 8,
  Marketing: 9,
  AllAccessViewer: 10
};

export const userRoles = Object.entries(systemRoles)
  .filter(([key, value]) => key !== 'Customer')
  .map(([key, value]) => ({ name: key, value }));

export const allUserRoles = [{ name: 'Customer', value: systemRoles.Customer }, ...userRoles];

export function allExcept(excludeRoles) {
  const allRoles = Object.values(systemRoles);

  return allRoles.filter((role) => !excludeRoles.includes(role));
}
