import { pagination } from '../../../configs/models/pagination';
import * as poa from '../types/proofOfAddressFilterTypes';

export const INITIAL_STATE = {
  Customer: '',
  From: '',
  To: '',
  Status: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const proofOfAddressFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case poa.ON_POA_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case poa.ON_POA_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default proofOfAddressFilterReducer;
