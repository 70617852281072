import * as tokenTypes from '../types/refreshTokenTypes';

const INITIAL_STATE = {
  loading: false,
  succeeded: false,
  message: '',
  errors: null
};

const refreshTokenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case tokenTypes.LOADING:
      return {
        ...INITIAL_STATE,
        loading: true
      };
    case tokenTypes.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case tokenTypes.REFRESH_TOKEN:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default refreshTokenReducer;
