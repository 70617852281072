import { combineReducers } from 'redux';
import documentReducer from './documentReducer';
import avatarReducer from './avatarReducer';
import accountOfficerReducer from './accountOfficerReducer';
import cardReducer from './cardReducer';
import walletReducer from './walletReducer';
import virtualAccountReducer from './virtualAccountReducer';
import lockCustomerReducer from './lockCustomerReducer';
import savingsProductReducer from './savingsProductReducer';
import transHistoryDetailsReducer from './transHistoryDetailsReducer';
import goalsDetailsReducer from './goalsDetailsReducer';
import goalsTransactionsReducer from './goalsTransactionsReducer';
import subscriptionStatusReducer from './subscriptionStatusReducer';
import fixedDepositDetailsReducer from './fixedDepositDetailsReducer';
import fixedDepositTransactionsReducer from './fixedDepositTransactionsReducer';
import investmentsProductReducer from './investmentsProductReducer';
import userInvestmentReducer from './userInvestmentReducer';
import investmentTransactionsReducer from './investmentTransactionsReducer';
import savingsReportDetailsReducer from './savingsReportDetailsReducer';
import goalsReportDetailsReducer from './goalsReportDetailsReducer';
import fixedFundsReportDetailsReducer from './fixedFundsReportDetailsReducer';
import reportsExportReducer from './reportsExportReducer';
import deleteSystemUserReducer from './deleteSystemUserReducer';
import lockSystemUserReducer from './lockSystemUserReducer';
import activityLogReducer from './activityLogReducer';
import extendExpiresMinReducer from './extendExpiresMinReducer';
import bvnImageReducer from './bvnImageReducer';
import changePassWordReducer from './changePasswordModalReducer';
import supportDetailsReducer from './supportDetailsReducer';
import deleteProfilePhotoReducer from './deleteProfilePhotoReducer';
import resetUserPasswordReducer from './resetUserPasswordReducer';
import pendindWithdrawalsReducer from './pendingWithdrawalsReducer';
import rejectWithdrawalReducer from './rejectWithdrawalReducer';
import campaignsReducer from './changeCampaignsReducer';
import editCampaignsReducer from './editCampaignsReducer';
import changeCustomersTypeReducer from './changeCustomersTypeReducer';
import rejectAddressReducer from './rejectAddressReducer';
import getRejectedAddrReducer from './getRejectedAddrReducer';
import changeStatReducer from './changeContractStatusReducer';
import volumeTypesReducer from './volumeTypesReducer';
import editAddressModalReducer from './editAddressReducer';

export default combineReducers({
  document: documentReducer,
  avatar: avatarReducer,
  bvnImage: bvnImageReducer,
  accountOfficer: accountOfficerReducer,
  card: cardReducer,
  wallet: walletReducer,
  virtual: virtualAccountReducer,
  lockCustomer: lockCustomerReducer,
  savingsProduct: savingsProductReducer,
  investmentsProduct: investmentsProductReducer,
  userInvestment: userInvestmentReducer,
  transHistoryDetails: transHistoryDetailsReducer,
  goalsDetails: goalsDetailsReducer,
  gaolsTransactions: goalsTransactionsReducer,
  subscriptionStatus: subscriptionStatusReducer,
  fixedDepositDetails: fixedDepositDetailsReducer,
  fixedDepositTransactions: fixedDepositTransactionsReducer,
  investmentTransactions: investmentTransactionsReducer,
  savingsReportDetails: savingsReportDetailsReducer,
  goalsReportDetails: goalsReportDetailsReducer,
  fixedFundsReportDetails: fixedFundsReportDetailsReducer,
  reportsExport: reportsExportReducer,
  deleteSystemUser: deleteSystemUserReducer,
  rejectAddress: rejectAddressReducer,
  getRejectedAddr: getRejectedAddrReducer,
  deleteProfilePhoto: deleteProfilePhotoReducer,
  lockSystemUser: lockSystemUserReducer,
  resetUserPassword: resetUserPasswordReducer,
  activityLog: activityLogReducer,
  supportDetails: supportDetailsReducer,
  extendExpiresMin: extendExpiresMinReducer,
  changePassword: changePassWordReducer,
  pendingWithdrawals: pendindWithdrawalsReducer,
  rejectWithdrawal: rejectWithdrawalReducer,
  changeCampaigns: campaignsReducer,
  editCampaign: editCampaignsReducer,
  changeCustomersType: changeCustomersTypeReducer,
  changeContractStat: changeStatReducer,
  treasuryVolume: volumeTypesReducer,
  editAddressModal: editAddressModalReducer
});
