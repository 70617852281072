import { lazy } from 'react';

const PagesRoutes = [
  {
    path: '/pages/blank-page',
    component: lazy(() => import('../../view/pages/blank')),
    layout: 'VerticalLayout'
  },
  {
    path: '/pages/error-page',
    component: lazy(() => import('../../view/pages/error')),
    layout: 'FullLayout'
  }
];

export default PagesRoutes;
