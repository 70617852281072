import * as modalTypes from '../types/virtualAccountTypes';

const INITIAL_STATE = {
  create: {
    open: false,
    data: null
  },
  delete: {
    open: false,
    data: null
  }
};

const virtualReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.OPEN_CREATE_VIRTUAL_MODAL:
      return {
        ...state,
        create: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_CREATE_VIRTUAL_MODAL:
      return {
        ...state,
        create: {
          open: false,
          data: null
        }
      };
    case modalTypes.OPEN_DELETE_VIRTUAL_MODAL:
      return {
        ...state,
        delete: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_DELETE_VIRTUAL_MODAL:
      return {
        ...state,
        delete: {
          open: false,
          data: null
        }
      };
    default:
      return state;
  }
};

export default virtualReducer;
