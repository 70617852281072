import { Modal, Form, Input, Button } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closePasswordModal } from '../../../../redux/app/modal/actions/changePasswordModalAction';
import { changeUserPassword } from '../../../../redux/auth/actions/changePasswordAction';

const formProps = {
  layout: 'vertical',
  name: 'basic'
};

const initialValues = {
  currentPassword: '',
  newPassword: ''
};

export default function ChangePassword() {
  const dispatch = useDispatch();
  const dialog = useSelector((store) => store.app.modal.changePassword);
  const { loading, succeeded } = useSelector((store) => store.auth.changeUserPassword);
  const [form] = Form.useForm();

  useEffect(() => {
    if (succeeded) {
      form.resetFields();
      dispatch(closePasswordModal());
    }
  }, [form, succeeded, dispatch]);

  const onFinish = ({ currentPassword, newPassword }) => {
    const formData = { currentPassword, newPassword };
    dispatch(changeUserPassword(formData));
  };

  return (
    <Modal
      title="Change Password"
      visible={dialog.open}
      onCancel={() => dispatch(closePasswordModal())}
      footer={null}
      width="450px"
      bodyStyle={{ padding: '24px 12px' }}>
      <Form
        {...formProps}
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        layout="vertical">
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: 'Please enter your current password'
            }
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
              message:
                'Your password must have at least eight characters and be made up of uppercase, lowercase, number and special characters.'
            }
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: 'Please confirm your new password'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
              }
            })
          ]}>
          <Input.Password />
        </Form.Item>
        <Button type="primary" htmlType="submit" block loading={loading}>
          Save
        </Button>
      </Form>
    </Modal>
  );
}
