import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { systemRoles } from '../configs/models/system-roles';
import { getUserRole, tokenHelper, verifyToken } from '../helpers';

const ProtectedRoute = (props) => {
  const location = useLocation();
  const token = tokenHelper.getToken();
  const role = getUserRole();
  const hasReadAccess =
    role === systemRoles.BusinessAdministrator || role === systemRoles.AllAccessViewer;

  useEffect(() => {
    const intervalRef = setInterval(verifyToken, 1000);

    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  if (token && (hasReadAccess || props.roles?.includes(role) || !props.roles?.length)) {
    return <Route {...props} />;
  } else if (!props.roles?.includes(role)) {
    return <Redirect from={props.path} to="/pages/error-page" />;
  }

  return <Redirect to={{ pathname: '/', state: { from: location } }} />;
};

export default ProtectedRoute;
