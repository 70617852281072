import { combineReducers } from 'redux';
import leaderboardReducer from './leaderboardReducer';
import referralsReducer from './referralsReducer';
import campaignsReducer from './campaignsReducer';

export default combineReducers({
  leaderboard: leaderboardReducer,
  referral: referralsReducer,
  campaigns: campaignsReducer
});
