export const errors = {
  email: 'Invalid email',
  password: 'Passowrd does not match',
  login: 'Username or password is not correct',
  authorized: 'This user does not have permission'
};

export const success = {
  login: 'Login successfully'
};
