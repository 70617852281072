import * as changePassword from '../types/changePasswordTypes';
import { openNotification } from '../../../helpers';
import httpService from '../../../services/httpService';
import apiRoutes from '../../../services/apiRoutes';

export const changeUserPassword = (data) => {
  const request = httpService.post(apiRoutes.auth.changePassword, data);

  return (dispatch) => {
    dispatch(isLoading(changePassword.LOADING));
    request.then((response) => {
      if (response.succeeded) {
        Promise.all([
          dispatch({
            type: changePassword.CHANGE_USER_PASSWORD,
            payload: response
          })
        ]).then(() => {
          openNotification('success', { message: response.message });
        });
      } else {
        openNotification('error', { message: response.message });
        dispatch(isLoading(changePassword.CHANGE_USER_PASSWORD_ERROR));
      }
    });
  };
};
export const isLoading = (loader) => {
  return {
    type: loader
  };
};
