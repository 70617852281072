import { combineReducers } from 'redux';
import transactionsReducer from './transactionsReducer';
import productSubscriptionReducer from './productSubscriptionReducer';
import savingsSubscriptionReducer from './savingsSubscriptionReducer';
import goalsSubscriptionReducer from './goalsSubscriptionReducer';
import fixedFundsSubscriptionReducer from './fixedFundsSubscriptionReducer';
import inflowReportReducer from './inflowReportReducer';
import transactionAnalysisReducer from './transactionAnalysisReducer';
import withdrawalReportReducer from './withdrawalReportReducer';
import pendingWithdrawalReducer from './pendingWithdrawalReducer';
import withdrawalDetailsReducer from './withdrawalDetailsReducer';
import internalBankAccountReducer from './internalBankAccountReducer';
import updateStatusReducer from './updateCustomersStatusReducer';

export default combineReducers({
  transactions: transactionsReducer,
  subscriptions: productSubscriptionReducer,
  savingsSubscription: savingsSubscriptionReducer,
  goalsSubscription: goalsSubscriptionReducer,
  fixedFundsSubscription: fixedFundsSubscriptionReducer,
  inflowReport: inflowReportReducer,
  withdrawalReport: withdrawalReportReducer,
  pendingWithdrawal: pendingWithdrawalReducer,
  withdrawalDetails: withdrawalDetailsReducer,
  internalBankAccount: internalBankAccountReducer,
  transactionAnalysis: transactionAnalysisReducer,
  updateCustomersStatus: updateStatusReducer
});
