import * as supportRequestsTypes from '../types/supportRequestsTypes';

const INITIAL_STATE = {
  loading: false,

  data: [],
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const supportRequestsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case supportRequestsTypes.LOADING:
      return {
        ...INITIAL_STATE,
        loading: true
      };
    case supportRequestsTypes.GET_SUPPORT_REQUEST:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    default:
      return state;
  }
};

export default supportRequestsReducer;
