import * as volumeTypes from '../types/updateTrasuryVolumeTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  userId: null,
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const updateTrasuryVolumeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case volumeTypes.LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case volumeTypes.UPDATE_TREASURY_VOLUME:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default updateTrasuryVolumeReducer;
