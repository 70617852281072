import { combineReducers } from 'redux';
import productsReducer from './productsReducer';
import productReducer from './productReducer';
import regularSubDetailsReducer from './regularSubDetailsReducer';
import subscriptionSummaryReducer from './subscriptionSummaryReducer';
import transactionHistoryReducer from './transactionHistoryReducer';
import transactionHistoryDetailsReducer from './transactionHistoryDetailsReducer';
import goalsReducer from './goalsReducer';
import goalsDetailsReducer from './goalsDetailsReducer';
import fixedDepositsReducer from './fixedDepositsReducer';
import fixedDepositDetailsReducer from './fixedDepositDetailsReducer';

export default combineReducers({
  products: productsReducer,
  product: productReducer,
  regularSubDetails: regularSubDetailsReducer,
  subscriptionSummary: subscriptionSummaryReducer,
  transactionHistory: transactionHistoryReducer,
  transactionHistoryDetails: transactionHistoryDetailsReducer,
  goals: goalsReducer,
  goalsDetails: goalsDetailsReducer,
  fixedDeposits: fixedDepositsReducer,
  fixedDepositDetails: fixedDepositDetailsReducer
});
