import * as regularSubDetailsTypes from '../types/regularSubDetailsTypes';

const INITIAL_STATE = {
  loading: false,
  userId: null,

  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const regularSubscriptionDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case regularSubDetailsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case regularSubDetailsTypes.GET_REGULAR_SUB_DETAILS:
      return {
        ...state,
        loading: false,
        userId: action?.userId,
        ...action.payload
      };
    case regularSubDetailsTypes.GET_REGULAR_SUB_DETAILS_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default regularSubscriptionDetailsReducer;
