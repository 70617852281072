export const userStorage = {
  getUserData() {
    const data = localStorage.getItem('user');
    return JSON.parse(data) || {};
  },
  setUserData(user) {
    return localStorage.setItem('user', JSON.stringify(user));
  },
  clearUserData() {
    return localStorage.removeItem('user');
  }
};

export const expiresinStorage = {
  getExpiresin() {
    const expiresIn = localStorage.getItem('expiresin');
    return Number(expiresIn);
  },
  setExpiresin(expiresin) {
    return localStorage.setItem('expiresin', new Date().getTime() + expiresin);
  },
  clearExpiresin() {
    return localStorage.removeItem('expiresin');
  }
};

export const downloadOptionStorage = {
  get() {
    return localStorage.getItem('downloadOption') === 'true';
  },
  set(option) {
    return localStorage.setItem('downloadOption', option);
  },
  clear() {
    return localStorage.removeItem('downloadOption');
  }
};

export function verifyToken() {
  const expiresin = expiresinStorage.getExpiresin();

  if (expiresin) {
    if (new Date().getTime() > expiresin) {
      localStorage.clear();
      window.location.replace('/');
    }
  }
}
