import * as fixedDepositTypes from '../types/fixedDepositTypes';

const INITIAL_STATE = {
  loading: false,

  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const fixedDepositDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fixedDepositTypes.LOADING_DETAILS:
      return {
        ...state,
        loading: true
      };
    case fixedDepositTypes.GET_FIXED_DEPOSIT_DETAILS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default fixedDepositDetailsReducer;
