import { combineReducers } from 'redux';
import transHistoryReducer from './transHistoryReducer';
import walletReducer from './walletReducer';
import virtualAccountReducer from './virtualAccountReducer';

export default combineReducers({
  transHistory: transHistoryReducer,
  wallet: walletReducer,
  virtual: virtualAccountReducer
});
