import { combineReducers } from 'redux';
import kycDocModalReducer from './kycDocModalReducer';
import kycDocReducer from './kycDocReducer';
import kycBVNModalReducer from './kycBVNModalReducer';
import kycBVNReducer from './kycBVNReducer';
import uploadKycDocModalReducer from './uploadKycDocModalReducer';
import uploadCustomerKYCDoc from './uploadCustomerKYCDocReducer';

export default combineReducers({
  kycDocModal: kycDocModalReducer,
  kycDoc: kycDocReducer,
  kycBVNModal: kycBVNModalReducer,
  kycBVN: kycBVNReducer,
  uploadKycDoc: uploadKycDocModalReducer,
  uploadCustomerKyc: uploadCustomerKYCDoc
});
