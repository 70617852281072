import * as customerTypes from '../types/customerTypes';

const INITIAL_STATE = {
  loading: false,
  data: null,

  succeeded: false,
  message: '',
  errors: null
};

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case customerTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case customerTypes.GET_CUSTOMER:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case customerTypes.LOCK_CUSTOMER:
      return {
        ...state,
        loading: false
      };
    case customerTypes.DELETE_CUSTOMER:
      return {
        ...state,
        loading: false
      };
    case customerTypes.REFRESH_CUSTOMER:
      return {
        ...state,
        data: null
      };
    default:
      return state;
  }
};

export default customerReducer;
