import * as transactionAnalysis from '../types/transactionAnalysisTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const transactionAnalysisReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transactionAnalysis.LOADING:
      return {
        ...state,
        loading: true
      };
    case transactionAnalysis.GET_TRANSACTION_ANAYSIS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default transactionAnalysisReducer;
