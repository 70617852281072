export const LOADING = '[CUSTOMER] GET_INVESTMENT_SUBSCRIPTIONS_LOADING';

export const GET_INVESTMENT_SUBSCRIPTIONS = '[CUSTOMER] GET_INVESTMENT_SUBSCRIPTIONS';
export const GET_INVESTMENT_SUBSCRIPTIONS_ERROR = '[CUSTOMER] GET_INVESTMENT_SUBSCRIPTIONS_ERROR';

export const GET_INVESTMENT_SUB_DETAILS = '[CUSTOMER] GET_INVESTMENT_SUBS_DETAILS';
export const GET_INVESTMENT_SUB_DETAILS_ERROR = '[CUSTOMER] GET_INVESTMENT_SUB_DETAILS_ERR';

export const CHANGE_INVESTMENT_STATUS = '[CUSTOMER] CHANGE_INVESTMENT_STATUS';
export const CHANGE_INVESTMENT_STATUS_ERROR = '[CUSTOMER] CHANGE_INVESTMENT_STATUS_ERROR';

export const REFRESH_SUBSCRIPTION = '[CUSTOMER] REFRESH_SUBSCRIPTION';
export const REFRESH_SUBSCRIPTION_ERROR = '[CUSTOMER] REFRESH_SUBSCRIPTION_ERROR';
