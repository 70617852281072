import * as subscriptionSummaryTypes from '../types/subscriptionSummaryTypes';

const INITIAL_STATE = {
  loading: false,
  userId: null,

  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const subscriptionSummaryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case subscriptionSummaryTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case subscriptionSummaryTypes.GET_SUBSCRIPTION_SUMMARY:
      return {
        ...state,
        loading: false,
        userId: action?.userId,
        ...action.payload
      };
    case subscriptionSummaryTypes.CHANGE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        loading: false
      };

    case subscriptionSummaryTypes.CHANGE_SUBSCRIPTION_STATUS_ERROR:
      return {
        ...state,
        loading: false
      };
    case subscriptionSummaryTypes.CHANGE_TARGET_SAVINGS_SUB:
      return {
        ...state,
        loading: false
      };
    case subscriptionSummaryTypes.CHANGE_TARGET_SAVINGS_SUB_ERR:
      return {
        ...state,
        loading: false
      };
    case subscriptionSummaryTypes.CHANGE_FIXED_DEPOSIT_SUB:
      return {
        ...state,
        loading: false
      };
    case subscriptionSummaryTypes.CHANGE_FIXED_DEPOSIT_SUB_ERR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default subscriptionSummaryReducer;
