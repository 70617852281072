import { pagination } from '../../../configs/models/pagination';
import * as kyc from '../types/kycDetailsFilterTypes';

export const INITIAL_STATE = {
  Customer: '',
  Tier: '',
  From: '',
  To: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const kycDetailsFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case kyc.ON_KYC_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case kyc.ON_KYC_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default kycDetailsFilterReducer;
