import * as loginTypes from '../types/loginTypes';

export const INITIAL_STATE = {
  loading: false,
  succeeded: false,
  message: '',
  errors: [],
  data: null
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case loginTypes.IS_SUBMITTING:
      return {
        ...state,
        loading: true
      };
    case loginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case loginTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case loginTypes.UNAUTHORIZED_ACCESS:
      return {
        ...INITIAL_STATE,
        ...action.payload
      };
    case loginTypes.RESET:
      return {
        ...INITIAL_STATE
      };
    case loginTypes.LOGOUT:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default loginReducer;
