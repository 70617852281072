import * as investmentsTypes from '../types/investmentsTypes';

const INITIAL_STATE = {
  loading: false,
  userId: 0,

  data: [],
  investment: null,
  succeeded: false,
  message: '',
  errors: null
};

const investmentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case investmentsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case investmentsTypes.GET_INVESTMENT_SUBSCRIPTIONS:
      return {
        ...state,
        loading: false,
        userId: action.userId,
        ...action.payload
      };
    case investmentsTypes.GET_INVESTMENT_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loading: false
      };
    case investmentsTypes.GET_INVESTMENT_SUB_DETAILS:
      return {
        ...state,
        loading: false,
        investment: action.payload
      };
    case investmentsTypes.GET_INVESTMENT_SUB_DETAILS_ERROR:
      return {
        ...state,
        loading: false
      };
    case investmentsTypes.CHANGE_INVESTMENT_STATUS:
      return {
        ...state,
        loading: false
      };
    case investmentsTypes.CHANGE_INVESTMENT_STATUS_ERROR:
      return {
        ...state,
        loading: false
      };
    case investmentsTypes.REFRESH_SUBSCRIPTION:
      return {
        ...state,
        loading: false
      };
    case investmentsTypes.REFRESH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default investmentsReducer;
