import * as noticeBoardTypes from '../types/noticeBoardTypes';

const INITIAL_STATE = {
  loading: false,

  data: [],
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const noticeBoardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case noticeBoardTypes.LOADING:
      return {
        ...INITIAL_STATE,
        loading: true
      };
    case noticeBoardTypes.GET_NOTICE_BOARD:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case noticeBoardTypes.SET_NOTICE_BOARD_ERROR:
      return {
        ...state,
        loading: false
      };
    case noticeBoardTypes.SET_NOTICE_BOARD:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default noticeBoardReducer;
