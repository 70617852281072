import * as customersTypes from '../types/customersTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const customersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case customersTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case customersTypes.GET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default customersReducer;
