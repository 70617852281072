import decode from 'jwt-decode';

export const tokenHelper = {
  getToken() {
    return localStorage.getItem('token') || '';
  },
  setToken(token) {
    return localStorage.setItem('token', token);
  },
  clearToken() {
    return localStorage.removeItem('token');
  },
  getRefreshToken(){
    return localStorage.getItem("refreshToken") || '';
  },
  setRefreshToken(refreshToken){
    return localStorage.setItem('refreshToken', refreshToken);
  },
  clearRefreshToken() {
    return localStorage.removeItem('refreshToken');
  }
};

export const decodeToken = (token) => {
  if (!token) return null;
  return decode(token);
};

export const getUserRole = () => {
  let token = decode(tokenHelper.getToken());
  if (!token) return null;
  return parseInt(token.role);
};
