import * as accountOfficerTypes from '../types/accountOfficerTypes';

const INITIAL_STATE = {
  loading: false,

  data: [],
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const accountOfficerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case accountOfficerTypes.GET_ACCOUNT_OFFICERS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case accountOfficerTypes.UPDATE_ACCOUNT_OFFICER:
      return {
        ...state,
        loading: false
      };
    case accountOfficerTypes.UPDATE_ACCOUNT_OFFICER_ERROR:
      return {
        ...state,
        loading: false
      };
    case accountOfficerTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default accountOfficerReducer;
