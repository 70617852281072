import * as modalTypes from '../types/fixedFundsReportDetailsTypes';

const INITIAL_STATE = {
  open: false,
  data: null
};

const fixedFundsReportDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.OPEN_MODAL:
      return {
        open: true,
        data: action.payload
      };
    case modalTypes.CLOSE_MODAL:
      return {
        open: false,
        data: null
      };
    default:
      return state;
  }
};

export default fixedFundsReportDetailsReducer;
