import * as userTypes from '../types/userTypes';
import { userStorage } from '../../../helpers/userStorage';

const INITIAL_STATE = {
  isLoggedIn: false,
  data: userStorage.getUserData() || null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SET_USER_DATA:
      return {
        ...state,
        data: action.payload
      };
    case userTypes.IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
