import * as complianceTypes from '../types';

const INITIAL_STATE = {
  loading: false,

  riskRatings: {
    data: [],
    page: 0,
    totalPages: 0,
    totalCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    succeeded: false,
    message: '',
    errors: null
  },
  riskRating: {
    data: false,
    errors: null,
    message: '',
    succeeded: false
  },
  userRiskRating: {
    data: false,
    errors: null,
    message: '',
    succeeded: false
  }
};

const riskAssessmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case complianceTypes.GET_RISK_RATINGS:
      return {
        ...state,
        loading: false,
        riskRatings: action.payload
      };
    case complianceTypes.GET_USER_RISK_RATINGS:
      return {
        ...state,
        loading: false,
        userRiskRating: action.payload
      };
    case complianceTypes.RESET_USER_RISK_RATINGS:
      return {
        ...state,
        userRiskRating: INITIAL_STATE.userRiskRating
      };
    case complianceTypes.UPDATE_RISK_RATING:
      return {
        ...state,
        loading: false,
        riskRating: action.payload
      };
    case complianceTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default riskAssessmentReducer;
