import * as inflowReportTypes from '../types/inflowReportTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const inflowReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case inflowReportTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case inflowReportTypes.GET_INFLOW_REPORT:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default inflowReportReducer;
