import * as changePassword from '../types/changePasswordTypes';

const INITIAL_STATE = {
  loading: false,
  succeeded: false,
  message: '',
  errors: null
};

const changeUserPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case changePassword.LOADING:
      return {
        ...INITIAL_STATE,
        loading: true
      };
    case changePassword.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        loading: false
      };
    case changePassword.CHANGE_USER_PASSWORD:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default changeUserPasswordReducer;
