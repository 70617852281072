import { pagination } from '../../../configs/models/pagination';
import * as referrals from '../types/referralsFilterTypes';

export const INITIAL_STATE = {
  Name: '',
  DateRangeFrom: '',
  DateRangeTo: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const referralsFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case referrals.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case referrals.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default referralsFilterReducer;
