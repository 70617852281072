import { pagination } from '../../../configs/models/pagination';
import * as feedback from '../types/feedbackFilterTypes';

export const INITIAL_STATE = {
  Category: '',
  CustomerName: '',
  CustomerMobileNumber: '',
  AccountOfficerId: '',
  DateRangeFrom: '',
  DateRangeTo: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const feedbackReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case feedback.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case feedback.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default feedbackReducer;
