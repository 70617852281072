import { combineReducers } from 'redux';
import kycDetailsReducer from './kycDetailsReducer';
import proofOfAddressReducer from './proofOfAddressReducer';
import riskModalReducer from './riskModalReducer';
import riskRatingReducer from './riskRatingReducer';
import pendingBvnCheckerReducer from './pendingBvnCheckerReducer';
import bvnCheckModalReducer from './bvnCheckerModalReducer';
import changeBVNStatusReducer from './changeBVNStatusReducer';

export default combineReducers({
  riskModal: riskModalReducer,
  riskRating: riskRatingReducer,
  kyc: kycDetailsReducer,
  poa: proofOfAddressReducer,
  pendingBvnChecker: pendingBvnCheckerReducer,
  bvnCheckerModal: bvnCheckModalReducer,
  changeBVNStatus: changeBVNStatusReducer
});
