import * as POATypes from '../types/proofOfAddressTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const proofOfAddressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POATypes.GET_POA:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case POATypes.GET_POA_STATS:
      return {
        ...state,
        loading: false,
        stats: action.payload
      };
    case POATypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default proofOfAddressReducer;
