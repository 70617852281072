import * as investmentsTypes from '../types/investmentsTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  investment: null,
  succeeded: false,
  message: '',
  errors: null
};

const investmentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case investmentsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case investmentsTypes.GET_INVESTMENTS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case investmentsTypes.GET_INVESTMENT_BY_ID:
      return {
        ...state,
        loading: false,
        investment: action.payload
      };
    case investmentsTypes.UPDATE_INVESTMENT:
      return {
        ...state,
        loading: false
      };
    case investmentsTypes.UPDATE_INVESTMENT_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default investmentsReducer;
