import * as modalTypes from '../types/extendExpiresMinTypes';

export const openModal = (data) => {
  return {
    type: modalTypes.OPEN_MODAL,
    payload: data
  };
};

export const closeModal = () => {
  return {
    type: modalTypes.CLOSE_MODAL
  };
};
