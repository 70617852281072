import { pagination } from '../../../configs/models/pagination';
import * as goalsSubReportFilterTypes from '../types/goalsSubReportFilterTypes';

export const INITIAL_STATE = {
  UserId: '',
  Customer: '',
  Status: '',
  From: '',
  To: '',
  Balance: '',
  BalanceType: '',
  Export: '',
  // DataSource: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const goalsSubscriptionReportFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case goalsSubReportFilterTypes.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case goalsSubReportFilterTypes.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default goalsSubscriptionReportFilterReducer;
