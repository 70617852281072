export const LOADING = '[SAVINGS_PRODUCT] SUBSCRIPTION_LOADING';

export const GET_SUBSCRIPTION_SUMMARY = '[SAVINGS_PRODUCT] GET_SUBSCRIPTION_SUMMARY';
export const CHANGE_SUBSCRIPTION_STATUS = '[SAVINGS_PRODUCT] CHANGE_SUBSCRIPTION_STATUS';
export const CHANGE_SUBSCRIPTION_STATUS_ERROR = '[SAVINGS_PRODUCT] CHANGE_SUBSCRIPTION_STATUS_ERR';

export const CHANGE_TARGET_SAVINGS_SUB = '[SAVINGS_PRODUCT] CHANGE_TARGET_SAVINGS_SUB_STATUS';
export const CHANGE_TARGET_SAVINGS_SUB_ERR = '[SAVINGS_PRODUCT] CHANGE_TARGET_SAVINGS_SUB_ERR';

export const CHANGE_FIXED_DEPOSIT_SUB = '[SAVINGS_PRODUCT] CHANGE_FIXED_DEPOSIT_SUB_STATUS';
export const CHANGE_FIXED_DEPOSIT_SUB_ERR = '[SAVINGS_PRODUCT] CHANGE_FIXED_DEPOSIT_SUB_ERR';
