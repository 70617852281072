import * as bankAccountsTypes from '../types/bankAccountsTypes';

const INITIAL_STATE = {
  loading: false,
  userId: 0,

  data: [],
  succeeded: false,
  message: '',
  errors: null
};

const bankAccountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bankAccountsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case bankAccountsTypes.GET_BANK_ACCOUNTS:
      return {
        ...state,
        loading: false,
        userId: action.userId,
        ...action.payload
      };
    case bankAccountsTypes.GET_BANK_ACCOUNTS_ERROR:
      return {
        ...state,
        loading: false
      };
    case bankAccountsTypes.REMOVE_BANK_ACCOUNT:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default bankAccountsReducer;
