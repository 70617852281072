import { openNotification } from '../../../helpers';
import { errors } from '../../../configs/messages/auth';

import * as loginTypes from '../types/loginTypes';
import httpService from '../../../services/httpService';
import apiRoutes from '../../../services/apiRoutes';
import history from '../../../configs/history';
import { tokenHelper } from '../../../helpers/tokenHelper';
import { userStorage, expiresinStorage } from '../../../helpers/userStorage';
import { setUserData } from './userActions';

// generate milliseconds
export const getExpiresMin = (min = 5) => 1000 * 60 * min;

export const login = (data) => {
  const request = httpService.post(apiRoutes.auth.signIn, {
    ...data,
    grantType: 'password',
    clientkey: `${process.env.REACT_APP_AUTH_CLIENT_KEY}`
  });

  return (dispatch) => {
    dispatch(loginReset());
    dispatch(isSubmitting());

    request
      .then((response) => {
        if (response.succeeded) {
          if (response?.data?.role === 1) {
            dispatch(unauthorizedAccess());
          } else {
            Promise.all([
              dispatch({
                type: loginTypes.LOGIN_SUCCESS,
                payload: response
              }),
              dispatch(setUserData(response))
            ]).then(() => {
              expiresinStorage.setExpiresin(getExpiresMin(response.data?.tokenValidityInMinutes));
              history.push('/dashboard');
            });
          }
        } else {
          openNotification('error', { message: errors.login, description: '' });
          dispatch({
            type: loginTypes.LOGIN_FAILURE,
            payload: response
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: loginTypes.LOGIN_FAILURE,
          payload: err?.response
        });
      });
  };
};

export const isSubmitting = () => {
  return {
    type: loginTypes.IS_SUBMITTING
  };
};

export const loginReset = () => {
  return {
    type: loginTypes.RESET
  };
};

export const unauthorizedAccess = () => {
  openNotification('error', { message: errors.authorized, description: '' });
  return (dispatch) =>
    Promise.all([
      dispatch({
        type: loginTypes.UNAUTHORIZED_ACCESS,
        payload: {
          message: errors.authorized
        }
      })
    ]).then(() => history.push('/'));
};

export const logout = () => {
  return (dispatch) => {
    if (tokenHelper.getToken()) {
      tokenHelper.clearToken();
      userStorage.clearUserData();
      tokenHelper.clearRefreshToken();

      Promise.all([
        dispatch({
          type: loginTypes.LOGOUT
        })
      ]).then(() => history.push('/'));
    }
  };
};
