import { pagination } from '../../../configs/models/pagination';
import * as withdrawalReportFilterTypes from '../types/withdrawalReportFilterTypes';

export const INITIAL_STATE = {
  Customer: '',
  ApprovalStatus: '',
  WalletNumber: '',
  Status: '',
  Reference: '',
  IsExternal: '',
  IsCredited: '',
  From: '',
  To: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const withdrawalReportFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case withdrawalReportFilterTypes.ON_WITHDRAWAL_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case withdrawalReportFilterTypes.ON_WITHDRAWAL_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default withdrawalReportFilterReducer;
