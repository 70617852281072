export function disableDeprecatedWarning() {
  const consoleError = console.error.bind(console);

  console.error = (errObj, ...args) => {
    if (process.env.NODE_ENV === "development" && typeof errObj === "string" && errObj.includes("[antd: Menu]")) {
      return;
    }
    consoleError(errObj, ...args);
  };
}
