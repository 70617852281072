import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { tokenHelper } from '../helpers/tokenHelper';

const AuthRoute = (props) => {
  const { component: Component, ...rest } = props;
  const token = tokenHelper.getToken();

  return (
    <Route
      {...rest}
      render={(props) => (token ? <Redirect to="/dashboard" /> : <Component {...props} />)}
    />
  );
};

export default AuthRoute;
