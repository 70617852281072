import * as savingsSubscriptionTypes from '../types/savingsSubscriptionTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const savingsSubscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case savingsSubscriptionTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case savingsSubscriptionTypes.GET_SAVINGS_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case savingsSubscriptionTypes.EXPORT_SAVINGS_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        message: action.payload?.message
      };
    case savingsSubscriptionTypes.GET_SAVINGS_STATS:
      return {
        ...state,
        loading: false,
        stats: action.payload
      };
    default:
      return state;
  }
};

export default savingsSubscriptionReducer;
