import * as modalTypes from '../types/userInvestmentTypes';

const INITIAL_STATE = {
  details: {
    open: false,
    data: null
  },
  change: {
    open: false,
    data: null
  }
};

const userInvestmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.OPEN_USER_INVESTMENT_MODAL:
      return {
        ...state,
        details: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_USER_INVESTMENT_MODAL:
      return {
        ...state,
        details: {
          open: false,
          data: null
        }
      };
    case modalTypes.OPEN_CHANGE_INVESTMENT_STATUS_MODAL:
      return {
        ...state,
        change: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_CHANGE_INVESTMENT_STATUS_MODAL:
      return {
        ...state,
        change: {
          open: false,
          data: null
        }
      };
    default:
      return state;
  }
};

export default userInvestmentReducer;
