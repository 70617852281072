import { pagination } from '../../../configs/models/pagination';
import * as pendingBvnCheckerTypes from '../types/pendingBvnCheckerFilterTypes';

export const INITIAL_STATE = {
  Search: '',
  Status: '',
  DateRangeFrom: '',
  DateRangeTo: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const pendingBvnCheckerFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pendingBvnCheckerTypes.ON_BVNCHECKER_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case pendingBvnCheckerTypes.ON_BVNCHECKER_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default pendingBvnCheckerFilterReducer;
