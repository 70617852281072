import * as transactionHistoryTypes from '../types/transactionHistoryTypes';

const INITIAL_STATE = {
  loading: false,
  userId: null,

  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const transactionHistoryDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transactionHistoryTypes.LOADING_DETAILS:
      return {
        ...state,
        loading: true
      };
    case transactionHistoryTypes.GET_TRANSACTION_HISTORY_DETAILS:
      return {
        ...state,
        loading: false,
        userId: action?.userId,
        ...action.payload
      };
    default:
      return state;
  }
};

export default transactionHistoryDetailsReducer;
