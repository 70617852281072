import { pagination } from '../../../configs/models/pagination';
import * as leaderboard from '../types/leaderboardFilterTypes';

export const INITIAL_STATE = {
  Name: '',
  Code: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const leaderboadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case leaderboard.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case leaderboard.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default leaderboadReducer;
