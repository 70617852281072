import * as referralsTypes from '../types/customerReferralsTypes';

const INITIAL_STATE = {
  loading: false,
  customerReferrals: {
    userId: 0,
    page: 0,
    totalPages: 0,
    totalCount: 0,
    data: [],
    succeeded: false,
    message: '',
    errors: null
  },
  caDetails: {
    data: {},
    succeeded: false,
    message: '',
    errors: null
  }
};

const customerReferralsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case referralsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case referralsTypes.GET_REFERRALS:
      return {
        ...state,
        loading: false,
        customerReferrals: {
          ...state.customerReferrals,
          userId: action.userId,
          ...action.payload
        }
      };
    case referralsTypes.GET_CA_DETAILS:
      return {
        ...state,
        loading: false,
        caDetails: {
          ...state.caDetails,
          ...action.payload
        }
      };
    case referralsTypes.SEND_CONTRACT:
      return {
        ...state,
        loading: false
      };
    case referralsTypes.CHANGE_CONTRACT:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default customerReferralsReducer;
