import * as transactionHistoryTypes from '../types/transactionHistoryTypes';

const INITIAL_STATE = {
  loading: false,
  userId: null,

  data: [],
  page: 1,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const transactionHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transactionHistoryTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case transactionHistoryTypes.GET_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: false,
        userId: action?.userId,
        ...action.payload
      };
    default:
      return state;
  }
};

export default transactionHistoryReducer;
