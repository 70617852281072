import { pagination } from '../../../configs/models/pagination';
import * as fixedFundsSubReportFilterTypes from '../types/fixedFundsSubReportFilterTypes';

export const INITIAL_STATE = {
  InterestType: '',
  UserId: '',
  Customer: '',
  Status: '',
  From: '',
  To: '',
  Balance: '',
  BalanceType: '',
  Export: '',
  // DataSource: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const fixedFundsSubscriptionReportFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fixedFundsSubReportFilterTypes.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case fixedFundsSubReportFilterTypes.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default fixedFundsSubscriptionReportFilterReducer;
