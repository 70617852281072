import { pagination } from '../../../configs/models/pagination';
import * as campaigns from '../types/campaignsFilterTypes';

export const INITIAL_STATE = {
  Search: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const campaignsFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case campaigns.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case campaigns.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default campaignsFilterReducer;
