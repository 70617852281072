import * as bvnCheckerTypes from '../types/pendingBvnCheckerTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  id: null,
  stats: null,
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const pendingBvnCheckerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bvnCheckerTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case bvnCheckerTypes.GET_BVN_CHECKER:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default pendingBvnCheckerReducer;
