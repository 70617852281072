import * as productsTypes from '../types/productsTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],

  succeeded: false,
  message: '',
  errors: null
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case productsTypes.GET_PRODUCTS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default productsReducer;
