import { combineReducers } from 'redux';
import customerFilterReducer from './customerFilterReducer';
import onboardingFilterReducer from './onboardingFilterReducer';
import ratingFilterReducer from './ratingFilterReducer';
import accountOfficerFilterReducer from './accountOfficerFilterReducer';
import transHistoryFilterReducer from './transFilterReducer';
import systemUserFilterReducer from './systemUserFilterReducer';
import savingsProductFilterReducer from './savingsProductFilterReducer';
import investmentsProductFilterReducer from './investmentsProductFilterReducer';
import transactionReportFilterReducer from './transactionReportFilterReducer';
import subscriptionReportFilterReducer from './subscriptionReportFilterReducer';
import savingsSubReportFilterReducer from './savingsSubReportFilterReducer';
import goalsSubReportFilterReducer from './goalsSubReportFilterReducer';
import fixedFundsSubReportFilterReducer from './fixedFundsSubReportFilterReducer';
import auditLogFilterReducer from './auditLogFilterReducer';
import leaderboadReducer from './leaderboardFilterReducer';
import referralsFilterReducer from './referralsFilterReducer';
import inflowLogFilterReducer from './inflowReportFilterReducer';
import feedbackReducer from './feedbackFilterReducer';
import kycDetailsFilterReducer from './kycDetailsFilterReducer';
import proofOfAddressFilterReducer from './proofOfAddressFilterReducer';
import cbaDumpFilterReducer from './cbaDumpFilterReducer';
import transactionAnalysisFilterReducer from './transactionAnalysisFilterReducer';
import withdrawalReportFilterReducer from './withdrawalReportFilterReducer';
import pendingBvnCheckerFilterReducer from './pendingBvnCheckerFilterReducer';
import campaignsFilterReducer from './campaignsFilterReducer';

export default combineReducers({
  customer: customerFilterReducer,
  onboarding: onboardingFilterReducer,
  rating: ratingFilterReducer,
  accountOfficer: accountOfficerFilterReducer,
  auditLog: auditLogFilterReducer,
  transHistory: transHistoryFilterReducer,
  systemUser: systemUserFilterReducer,
  savingsProduct: savingsProductFilterReducer,
  investmentsProduct: investmentsProductFilterReducer,
  transactionReport: transactionReportFilterReducer,
  subscriptionReport: subscriptionReportFilterReducer,
  savingsSubReport: savingsSubReportFilterReducer,
  goalsSubReport: goalsSubReportFilterReducer,
  fixedFundsSubReport: fixedFundsSubReportFilterReducer,
  leaderboard: leaderboadReducer,
  referrals: referralsFilterReducer,
  inflowLog: inflowLogFilterReducer,
  withdrawal: withdrawalReportFilterReducer,
  transactionAnalysis: transactionAnalysisFilterReducer,
  feedback: feedbackReducer,
  kyc: kycDetailsFilterReducer,
  poa: proofOfAddressFilterReducer,
  cbaDump: cbaDumpFilterReducer,
  pendingBvnChecker: pendingBvnCheckerFilterReducer,
  campaigns: campaignsFilterReducer
});
