import * as goalsTypes from '../types/goalsTypes';

const INITIAL_STATE = {
  loading: false,

  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const goalsDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case goalsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case goalsTypes.GET_GOALS_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default goalsDetailsReducer;
