import * as fixedDepositTypes from '../types/fixedDepositTypes';

const INITIAL_STATE = {
  loading: false,
  userId: null,

  data: [],
  succeeded: false,
  message: '',
  errors: null
};

const fixedDepositsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fixedDepositTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case fixedDepositTypes.GET_FIXED_DEPOSITS:
      return {
        ...state,
        loading: false,
        userId: action?.userId,
        ...action.payload
      };
    default:
      return state;
  }
};

export default fixedDepositsReducer;
