import * as virtualAccountTypes from '../types/virtualAccountTypes';

const INITIAL_STATE = {
  loading: false,

  data: [],
  succeeded: false,
  message: '',
  errors: null
};

const virtualReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case virtualAccountTypes.CREATE_VIRTUAL_ACCOUNT:
      return {
        ...state,
        loading: false
      };
    case virtualAccountTypes.CREATE_VIRTUAL_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false
      };
    case virtualAccountTypes.DELETE_VIRTUAL_ACCOUNT:
      return {
        ...state,
        loading: false
      };
    case virtualAccountTypes.DELETE_VIRTUAL_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false
      };
    case virtualAccountTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default virtualReducer;
