import { notification } from 'antd';
import { RiCloseFill } from 'react-icons/ri';

/**
 *
 * @param {message, description} args
 */
export const openNotification = (type, args) => {
  notification[type]({
    message: '',
    description: '',
    closeIcon: <RiCloseFill className="remix-icon hp-text-color-black-80" size={24} />,
    ...args
  });
};

export const closeNotification = () => {
  notification.close();
};
