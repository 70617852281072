export const LOADING = '[CUSTOMER] LOADING';

export const GET_CUSTOMER = '[CUSTOMER] GET_CUSTOMER';
export const CREATE_CUSTOMER = '[CUSTOMER] CREATE_CUSTOMER';
export const UPDATE_CUSTOMER = '[CUSTOMER] UPDATE_CUSTOMER';
export const LOCK_CUSTOMER = '[CUSTOMER] LOCK_CUSTOMER';
export const REFRESH_CUSTOMER = '[CUSTOMER] REFRESH_CUSTOMER';
export const DELETE_CUSTOMER = '[CUSTOMER] DELETE_CUSTOMER';
export const DELETE_PROFILE_PHOTO = '[CUSTOMER] DELETE_PROFILE_PHOTO';
export const DELETE_PROFILE_PHOTO_ERROR = '[CUSTOMER] DELETE_PROFILE_PHOTO_ERROR';
