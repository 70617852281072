import { combineReducers } from 'redux';
import changeUserPasswordReducer from './changePasswordReducer';

import loginReducer from './loginReducer';
import refreshTokenReducer from './refreshTokenReducer';
import userReducer from './userReducer';

export default combineReducers({
  login: loginReducer,
  refreshToken: refreshTokenReducer,
  user: userReducer,
  changeUserPassword: changeUserPasswordReducer
});
