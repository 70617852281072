import * as createUserTypes from '../types/createUserTypes';


const INITIAL_STATE = {
    loading: false,
    succeeded: false,
    message: "",
    errors: null
};

const createUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createUserTypes.LOADING:
      return {
        ...INITIAL_STATE,
        loading: true
      };
    case createUserTypes.CREATE_INTERNAL_USER_ERROR:
      return {
        ...state,
        loading: false
      };
    case createUserTypes.CREATE_INTERNAL_USER :
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default createUserReducer;
