import * as fixedFundsSubscriptionTypes from '../types/fixedFundsSubscriptionTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const goalsSubscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case fixedFundsSubscriptionTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case fixedFundsSubscriptionTypes.GET_FIXED_FUNDS_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case fixedFundsSubscriptionTypes.EXPORT_FIXED_FUNDS_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        message: action.payload?.message
      };
    case fixedFundsSubscriptionTypes.GET_FIXED_FUNDS_STATS:
      return {
        ...state,
        loading: false,
        stats: action.payload
      };
    default:
      return state;
  }
};

export default goalsSubscriptionReducer;
