export const transactionReports = {
  General: 'General',
  Savings: 'Star Account',
  Goals: 'Goals',
  FixedFunds: 'Vault',
  MoneyMarketFund: 'Money Market Fund',
  HYISClassic: 'HYIS Classic',
  HYISPremium: 'HYIS Premium',
  TBills: 'Treasury Bills'
};

export const categories = {
  Savings: 'S',
  Investments: 'I'
};

export const transactionCategories = [
  { name: 'Savings', value: categories.Savings },
  { name: 'Investments', value: categories.Investments }
];

export const reportTagStatus = {
  '-2': 'processing',
  '-1': 'default',
  0: 'default',
  1: 'success',
  2: 'warning',
  3: 'error'
};

export const transactionReportStatus = {
  Processing: -2,
  Approved: -1,
  Pending: 0,
  Successful: 1,
  Failed: 2,
  Cancelled: 3
};

export const transactionStatus = [
  { key: 'Processing', value: transactionReportStatus.Processing },
  { key: 'Approved', value: transactionReportStatus.Approved },
  { key: 'Pending', value: transactionReportStatus.Pending },
  { key: 'Successful', value: transactionReportStatus.Successful },
  { key: 'Failed', value: transactionReportStatus.Failed },
  { key: 'Cancelled', value: transactionReportStatus.Cancelled }
];

export const subscriptionReportStatus = {
  Inactive: 0,
  Active: 1,
  Completed: 2,
  Cancelled: 3
};

export const subscriptionStatus = [
  { key: 'Inactive', value: subscriptionReportStatus.Inactive },
  { key: 'Active', value: subscriptionReportStatus.Active },
  { key: 'Completed', value: subscriptionReportStatus.Completed },
  { key: 'Cancelled', value: subscriptionReportStatus.Cancelled }
];

export const savingInvestmentProduct = {
  [transactionReports.Savings]: 1,
  [transactionReports.Goals]: 2,
  [transactionReports.FixedFunds]: 3,
  [transactionReports.HYISClassic]: 1,
  [transactionReports.HYISPremium]: 2,
  [transactionReports.MoneyMarketFund]: 3,
  [transactionReports.TBills]: 2
};

export const savingsProducts = [
  { name: [transactionReports.Savings], value: 1 },
  { name: [transactionReports.Goals], value: 2 },
  { name: [transactionReports.FixedFunds], value: 3 }
];

export const investmentProducts = [
  { name: [transactionReports.MoneyMarketFund], value: 3 },
  { name: [transactionReports.HYISClassic], value: 1 },
  { name: [transactionReports.HYISPremium], value: 2 },
  { name: [transactionReports.TBills], value: 4 }
];

export const balanceTypes = [
  { label: '<=', value: '0' },
  { label: '>=', value: '1' }
];

export const fixedFundsInterestTypes = {
  upfront: 1,
  maturity: 2
};

export const fundingSourceType = {
  Card: 1,
  VirtualAccount: 2
};

export const sourceTypes = Object.entries(fundingSourceType).map(([key, value]) => ({
  name: key,
  value
}));

export const allInflowReportstatus = {
  Initiated: -1,
  Pending: 0,
  Successful: 1,
  Failed: 2
};

export const allWithdrawalReportstatus = {
  Pending: 0,
  Successful: 1,
  Failed: 2,
  Cancelled: 3,
  Reversed: 4
};

export const inflowReportstatus = Object.entries(allInflowReportstatus).map(([key, value]) => ({
  name: key,
  value
}));

export const allApprovalstatus = {
  Pending: 0,
  Approved: 1,
  Rejected: 2
};

export const approvalstatus = Object.entries(allApprovalstatus).map(([key, value]) => ({
  name: key,
  value
}));
export const withdrawalReportstatus = Object.entries(allWithdrawalReportstatus).map(
  ([key, value]) => ({
    name: key,
    value
  })
);
export const cbaSyncStatus = {
  InProgress: -1,
  Pending: 0,
  Successful: 1,
  Failed: 2
};
