import * as productSubscriptionTypes from '../types/productSubscriptionTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const productSubscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productSubscriptionTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case productSubscriptionTypes.GET_GROUPED_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case productSubscriptionTypes.EXPORT_PRODUCT_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        message: action.payload?.message
      };
    default:
      return state;
  }
};

export default productSubscriptionReducer;
