import * as kycDocTypes from '../types/kycDocTypes';

const INITIAL_STATE = {
  loading: false,

  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const kycDocReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case kycDocTypes.APPROVE_REJECT_KYC_DOCUMENT:
      return {
        ...state,
        loading: false
      };
    case kycDocTypes.APPROVE_REJECT_KYC_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false
      };
    case kycDocTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default kycDocReducer;
