import * as uploadKycTypes from '../types/uploadCustomerKYCDocTypes';

const INITIAL_STATE = {
  loading: false,

  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const uploadCustomerKYCDoc = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case uploadKycTypes.UPLOAD_DOC:
      return {
        ...state,
        loading: false
      };
    case uploadKycTypes.UPLOAD_DOC_ERROR:
      return {
        ...state,
        loading: false
      };
    case uploadKycTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default uploadCustomerKYCDoc;
