import axios from 'axios';
import history from '../configs/history';
import { openNotification } from '../helpers';
import { tokenHelper } from '../helpers/tokenHelper';


const ApiRequest = async (type, url, data = {}, options) => {
  if (!options) {
    options = { headers: null, showMsg: true };
  }
  const endpoint_url = `${process.env.REACT_APP_API_GATEWAY_URL}${url}`;
  let queryString = '';
  const token = tokenHelper.getToken(); // get token from localstorage // tokenHelper.getToken();
  let service;
  let config;

  //return false;
  if (token) {
    config = {
      headers: options.headers ?? {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      }
    };
  } else {
    config = options.headers ?? {
      headers: {
        'Content-type': 'application/json'
      }
    };
  }

  switch (type.toLowerCase()) {
    case 'get':
      if (Object.keys(data).length) {
        queryString = `?${objectToQueryString(data)}`;
      }
      service = axios.get(endpoint_url + queryString, config);
      break;

    case 'post':
      // if (Object.keys(data).length) {
      //   queryString = `?${objectToQueryString(data)}`;
      // }
      // if (queryString !== '') 
      service = axios.post(endpoint_url, data, config);
       break;

    case 'post_no_query':
      service = axios.post(endpoint_url, data, config);
      break;

    case 'put':
      service = axios.put(endpoint_url, data, config);
      break;

    case 'delete':
      service = axios.delete(endpoint_url, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        data
      });
      break;

    case 'patch':
      service = axios.patch(endpoint_url, data, config);
      break;

    default:
      break;
  }

  try {
    await service;
    return service.then((a) => a.data);
  } catch (err) {
    let msg;
    let defaultMessage = 'Service error occured..';
    if (err.response) {
      if (err.response.status === 400) {
      } else if (err.response.status === 401) {
        localStorage.clear();
        history.push('/');
      }else if (err.response.status >= 403){
        msg = err.response.data.Message;
        openNotification('error', { message: msg})
      }
       else if ((err.response.status >= 500) & (err.response.status < 600)) {
        msg = defaultMessage;
      } else {
        msg =
          err.response.data.error ||
          err.response.data.message ||
          err.response.data.Message ||
          defaultMessage;
      }
    } else if (err.request) {
      msg = err.message;
    } else {
      // eslint-disable-next-line no-unused-vars
      msg = err.message;
    }

    //logger.error(err);
    var errData = err.response?.data;

    if (options.showMsg) {
      // toaster.error(
      //   errData.message ?? msg,
      //   5000,
      //   toaster.toastPosition.TOP_RIGHT
      // );
    }

    return {
      succeeded: errData?.succeeded,
      data: errData?.data,
      message: errData?.message,
      errors: errData?.errors
    };
  }
};

function objectToQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}

export const http = {
  get(url, data = {}, options) {
    return ApiRequest('get', url, data, options);
  },
  getWithExport(url, data = {}, options) {
    return ApiRequest('get_with_export', url, data, options);
  },
  post(url, data = {}, options) {
    return ApiRequest('post', url, data, options);
  },
  post_no_query(url, data = {}, options) {
    return ApiRequest('post_no_query', url, data, options);
  },
  patch(url, data = {}, options) {
    return ApiRequest('patch', url, data, options);
  },
  put(url, data = {}, options) {
    return ApiRequest('put', url, data, options);
  },
  delete(url, data = {}, options) {
    return ApiRequest('delete', url, data, options);
  }
};

export default http;
