import { Modal, Button, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../../../../redux/app/modal/actions/extendExpiresMinAction';
import { expiresinStorage, tokenHelper } from '../../../../helpers';
import { useEffect, useState } from 'react';
import { logout } from '../../../../redux/auth/actions';
import { extendToken } from '../../../../redux/auth/actions/refreshTokenActions';

export default function ShowExpirationModal(props) {
  const { setIsShown } = props;
  const [modalShown, setModalShown] = useState(false);
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.app.modal.extendExpiresMin);
  const modalDisplayTime = parseInt(process.env.REACT_APP_SESSION_TIME_BEFORE_EXPIRY_IN_SECONDS);
  const timeLeftCounter = modalDisplayTime / 1000;
  const [timeLeft, setTimeLeft] = useState(timeLeftCounter);
  const getRefreshToken = tokenHelper.getRefreshToken();
  const getToken = tokenHelper.getToken();

  const expireMin = expiresinStorage.getExpiresin() - Date.now();

  const [timeUntilExpiration, setTimeUntilExpiration] = useState(expireMin);

  useEffect(() => {
    if (!modalShown) {
      let timer = setTimeout(() => {
        dispatch(openModal());
        setIsShown(true);
        setModalShown(true);
        setTimeLeft(timeLeftCounter);
      }, 480000); // 9 minutes in milliseconds
      return () => {
        clearTimeout(timer);
      };
    }
  }, [modalShown, dispatch, modalDisplayTime, timeLeftCounter, setIsShown]);

  useEffect(() => {
    let interval;
    if (timeUntilExpiration) {
      interval = setInterval(() => {
        setTimeUntilExpiration(expiresinStorage.getExpiresin() - Date.now());
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeUntilExpiration, timeLeft]);

  return (
    <Modal
      title={`Session Warning`}
      visible={dialog.open && modalShown}
      onCancel={() => dispatch(logout())}
      footer={
        <div>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                dispatch(extendToken({ token: getToken, refreshToken: getRefreshToken }));
                dispatch(closeModal());
                setIsShown(false);
                setTimeout(() => {
                  setModalShown(false);
                }, 2000);
              }}>
              Extend
            </Button>
            <Button onClick={() => dispatch(logout())}>Close</Button>
          </Space>
        </div>
      }
      bodyStyle={{ padding: 24 }}>
      <p>
        Your session is about to expire in <b>{timeLeft} seconds.</b> Do you wish to extend your
        session right now?
      </p>
    </Modal>
  );
}
