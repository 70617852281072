import { lazy } from 'react';
import { systemRoles } from '../../configs/models/system-roles';
import { allExcept } from '../../configs/models/system-roles';

const AppRoutes = [
  {
    path: '/dashboard',
    component: lazy(() => import('../../view/app/dashboard')),
    layout: 'VerticalLayout',
    auth: true,
    roles: []
  },
  {
    path: '/customers/onboarding-requests',
    component: lazy(() => import('../../view/app/onboarding-requests')),
    layout: 'VerticalLayout',
    auth: true,
    roles: allExcept([systemRoles.Operations, systemRoles.Finance])
  },
  {
    path: '/customers',
    component: lazy(() => import('../../view/app/customers')),
    layout: 'VerticalLayout',
    auth: true,
    roles: allExcept([systemRoles.SystemAdministrator, systemRoles.Marketing])
  },
  {
    path: '/risk-assessment',
    component: lazy(() => import('../../view/app/compliance/risk-assessment')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/kyc',
    component: lazy(() => import('../../view/app/compliance/kyc')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/proof-of-address',
    component: lazy(() => import('../../view/app/compliance/proof-of-address')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/bvn-checker',
    component: lazy(() => import('../../view/app/compliance/bvn-checker')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/cba-dump',
    component: lazy(() => import('../../view/app/customers/cba-dump')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.SystemAdministrator, systemRoles.Operations]
  },

  {
    path: '/account-officers',
    component: lazy(() => import('../../view/app/account-officers')),
    layout: 'VerticalLayout',
    auth: true,
    roles: []
  },
  {
    path: '/audit-log',
    component: lazy(() => import('../../view/app/audit-log')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Audit, systemRoles.Compliance]
  },
  {
    path: '/system-users',
    component: lazy(() => import('../../view/app/system-users')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.SystemAdministrator]
  },
  {
    path: '/leaderboard',
    component: lazy(() => import('../../view/app/leaderboad')),
    layout: 'VerticalLayout',
    auth: true,
    roles: allExcept([
      systemRoles.Operations,
      systemRoles.Compliance,
      systemRoles.Audit,
      systemRoles.Finance
    ])
  },
  {
    path: '/campaigns',
    component: lazy(() => import('../../view/app/campaigns')),
    layout: 'VerticalLayout',
    auth: true,
    roles: allExcept([
      systemRoles.Operations,
      systemRoles.Compliance,
      systemRoles.Audit,
      systemRoles.Finance
    ])
  },
  {
    path: '/referrals',
    component: lazy(() => import('../../view/app/referrals')),
    layout: 'VerticalLayout',
    auth: true,
    roles: allExcept([
      systemRoles.Operations,
      systemRoles.Compliance,
      systemRoles.Audit,
      systemRoles.Finance
    ])
  },
  {
    path: '/notice-board',
    component: lazy(() => import('../../view/app/notice-board')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.SystemAdministrator, systemRoles.Marketing]
  },
  {
    path: '/support-requests',
    component: lazy(() => import('../../view/app/support-requests')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Sales, systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/products/savings',
    component: lazy(() => import('../../view/app/savings-products')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Finance, systemRoles.Operations]
  },
  {
    path: '/products/investments',
    component: lazy(() => import('../../view/app/savings-investments')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Finance, systemRoles.Operations]
  },
  {
    path: '/reports/transactions',
    component: lazy(() => import('../../view/app/reports/transactions')),
    layout: 'VerticalLayout',
    auth: true,
    roles: allExcept([systemRoles.SystemAdministrator, systemRoles.Marketing])
  },
  {
    path: '/reports/subscriptions',
    component: lazy(() => import('../../view/app/reports/subscriptions')),
    layout: 'VerticalLayout',
    auth: true,
    roles: allExcept([systemRoles.SystemAdministrator, systemRoles.Marketing])
  },
  {
    path: '/reports/inflow-report',
    component: lazy(() => import('../../view/app/reports/inflow-report')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Operations, systemRoles.Finance, systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/reports/card-transactions',
    component: lazy(() => import('../../view/app/reports/card-transactions')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Operations, systemRoles.Finance, systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/reports/virtual-account-transactions',
    component: lazy(() => import('../../view/app/reports/virtual-account-transactions')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Operations, systemRoles.Finance, systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/reports/withdrawal-report',
    component: lazy(() => import('../../view/app/reports/withdrawal-report')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Operations, systemRoles.Finance, systemRoles.Compliance, systemRoles.Audit]
  },
  {
    path: '/reports/pending-withdrawal',
    component: lazy(() => import('../../view/app/reports/pending-withdrawal')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Operations, systemRoles.Finance, systemRoles.Audit]
  },
  {
    path: '/reports/transaction-analysis',
    component: lazy(() => import('../../view/app/reports/transaction-analysis')),
    layout: 'VerticalLayout',
    auth: true,
    roles: [systemRoles.Operations, systemRoles.Finance, systemRoles.Audit]
  }
];

export default AppRoutes;
