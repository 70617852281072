const randomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < charactersLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const getSplittedString = (value, seperator, index) => {
  let splittedValue = value.split(seperator);
  return splittedValue[index];
};

const isNullOrEmpty = (value) => {
  return value === undefined || value === null || value === '';
};

const capitalize = (str) => {
  if (!str) return '';
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

const camelCaseToSentence = (str) => {
  if (!str) return '';
  const regex = new RegExp(/(?=[A-Z])/g);
  const sentence = str.replace(regex, ' ');
  return sentence[0].toUpperCase() + sentence.slice(1);
};

export const isValidUrl = (str) => {
  if (!str) return '';
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return regex.test(str);
};

export const StringUtility = {
  randomString,
  getSplittedString,
  isNullOrEmpty,
  capitalize,
  camelCaseToSentence,
  isValidUrl
};
