import { pagination } from '../../../configs/models/pagination';
import * as transactionReportFilterTypes from '../types/transactionReportFilterTypes';

export const INITIAL_STATE = {
  UserId: '',
  Category: '',
  ProductId: '',
  ProductType: '',
  Customer: '',
  Status: '',
  From: '',
  To: '',
  Export: '',
  // DataSource: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const transactionReportFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transactionReportFilterTypes.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case transactionReportFilterTypes.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default transactionReportFilterReducer;
