import { pagination } from '../../../configs/models/pagination';
import * as inflowReportFilterTypes from '../types/inflowReportFilterTypes';

export const INITIAL_STATE = {
  Customer: '',
  WalletNumber: '',
  SourceType: '',
  Status: '',
  Reference: '',
  From: '',
  To: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const inflowReportFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case inflowReportFilterTypes.ON_INFLOW_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case inflowReportFilterTypes.ON_INFLOW_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default inflowReportFilterReducer;
