import * as modalTypes from '../types/savingsProductTypes';

const INITIAL_STATE = {
  edit: {
    open: false,
    data: null
  },
  view: {
    open: false,
    data: null
  }
};

const savingsProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.OPEN_PRODUCT_MODAL:
      return {
        ...state,
        edit: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_PRODUCT_MODAL:
      return {
        ...state,
        edit: {
          open: false,
          data: null
        }
      };
    case modalTypes.OPEN_PRODUCT_DETAILS_MODAL:
      return {
        ...state,
        view: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_PRODUCT_DETAILS_MODAL:
      return {
        ...state,
        view: {
          open: false,
          data: null
        }
      };
    default:
      return state;
  }
};

export default savingsProductReducer;
