import * as tokenTypes from '../types/refreshTokenTypes';
import httpService from '../../../services/httpService';
import apiRoutes from '../../../services/apiRoutes';
import { expiresinStorage } from '../../../helpers/userStorage';
import { tokenHelper } from '../../../helpers/tokenHelper';
// import history from '../../../configs/history';
import { setUserData } from './userActions';
import { getExpiresMin } from './loginActions';
// import * as loginTypes from '../types/loginTypes';
// import { errors } from '../../../configs/messages/auth';

export const extendToken = (data) => {
  const request = httpService.post(apiRoutes.auth.refreshToken, data);
  return (dispatch, getState) => {
    dispatch(isLoading(tokenTypes.LOADING));
    request.then((response) => {
      if (response.succeeded) {
        const token = tokenHelper.getToken(); // Get token from local storage
        if (token) {
          tokenHelper.clearToken();
          tokenHelper.clearRefreshToken();
          Promise.all([
            dispatch({ type: tokenTypes.REFRESH_TOKEN, payload: response }),
            dispatch(setUserData(response))
          ]).then(() => {
            const expiresIn = getExpiresMin(response.data?.tokenValidityInMinutes);
            expiresinStorage.setExpiresin(expiresIn);
            const userData = token; // Get user data from local storage
            userData && dispatch(setUserData(userData)); // Dispatch user data from local storage
          });
        }
      } else {
        // Save the error response to the Redux store
        dispatch({
          type: tokenTypes.REFRESH_TOKEN_ERROR,
          payload: response
        });

        // Use the user data from local storage if available
        const userData = tokenHelper.getToken();
        if (userData) {
          expiresinStorage.setExpiresin(getExpiresMin(userData.data?.tokenValidityInMinutes));
          dispatch(setUserData(userData));
        }
      }
    });
  };
};

export const isLoading = (loading) => {
  return {
    type: loading
  };
};
