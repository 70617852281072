import * as productTypes from '../types/productTypes';

const INITIAL_STATE = {
  loading: false,
  data: null,
  succeeded: false,
  message: '',
  errors: null
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case productTypes.GET_PRODUCT_BY_ID:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case productTypes.UPDATE_PRODUCT:
      return {
        ...state,
        loading: false
      };
    case productTypes.UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default productReducer;
