import * as getCBATypes from '../types/getCBADumpTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  userId: null,
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const getCBADumpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case getCBATypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case getCBATypes.GET_CBA_DUMP:
      return {
        ...state,
        loading: false,
        userId: action.userId,
        ...action.payload
      };
    case getCBATypes.EXPORT_CBA_DUMP:
      return {
        ...state,
        loading: false,
        message: action.payload?.message
      };

    default:
      return state;
  }
};

export default getCBADumpReducer;
