import * as goalsSubscriptionTypes from '../types/goalsSubscriptionTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const goalsSubscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case goalsSubscriptionTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case goalsSubscriptionTypes.GET_GOALS_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case goalsSubscriptionTypes.EXPORT_GOALS_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loading: false,
        message: action.payload?.message
      };
    case goalsSubscriptionTypes.GET_GOALS_STATS:
      return {
        ...state,
        loading: false,
        stats: action.payload
      };
    default:
      return state;
  }
};

export default goalsSubscriptionReducer;
