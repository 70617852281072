import * as modalTypes from '../types/kycDocModalTypes';

const INITIAL_STATE = {
  open: false,
  type: '',
  data: null
};

const kycDocModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.OPEN_DOCUMENT_MODAL:
      return {
        ...state,
        open: true,
        data: action.payload
      };
    case modalTypes.CLOSE_DOCUMENT_MODAL:
      return {
        ...state,
        open: false,
        data: null
      };
    default:
      return state;
  }
};

export default kycDocModalReducer;
