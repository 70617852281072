import * as pendingWithdrawalType from '../types/withdrawalDetailsTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const withdrawalDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pendingWithdrawalType.LOADING:
      return {
        ...state,
        loading: true
      };
    case pendingWithdrawalType.GET_WITHDRAWAL_DETAILS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case pendingWithdrawalType.APPROVE_WITHDRAWAL:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case pendingWithdrawalType.REJECT_WITHDRAWAL:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

// export const internalBankAccountReducer = (state = INITIAL_STATE, action) => {
//   switch (action.type) {
//     case pendingWithdrawalType.LOADING:
//       return {
//         ...state,
//         loading: true
//       };
//     case pendingWithdrawalType.GET_BANK_ACCOUNT:
//       return {
//         ...state,
//         loading: false,
//         ...action.payload
//       };
//     default:
//       return state;
//   }
// };

export default withdrawalDetailsReducer;
