import * as modalTypes from '../types/changePasswordModalTypes';

export const openPasswordModal = (data) => {
  return {
    type: modalTypes.OPEN_PASSWORD_MODAL,
    payload: data
  };
};

export const closePasswordModal = () => {
  return {
    type: modalTypes.CLOSE_PASSWORD_MODAL
  };
};
