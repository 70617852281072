import * as walletTypes from '../types/walletTypes';

const INITIAL_STATE = {
  loading: false,

  data: [],
  succeeded: false,
  message: '',
  errors: null
};

const walletReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case walletTypes.FREEZE_UNFREEZE_WALLET:
      return {
        ...state,
        loading: false
      };
    case walletTypes.ACTIVATE_DEACTIVATE_WALLET:
      return {
        ...state,
        loading: false
      };
    case walletTypes.DELETE_WALLET:
      return {
        ...state,
        loading: false
      };
    case walletTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default walletReducer;
