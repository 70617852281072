import {
  Category,
  Chart,
  Home,
  People,
  ShieldDone,
  Work,
  Activity,
  Notification,
  Message
} from 'react-iconly';
import { fixedFundsInterestTypes, savingInvestmentProduct } from '../../configs/models/reports';
import { systemRoles } from '../../configs/models/system-roles';
import { allExcept } from '../../configs/models/system-roles';
const pages = [
  {
    header: 'Pages'
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <Home set="curved" className="remix-icon" />,
    navLink: '/dashboard',
    roles: []
  },
  {
    id: 'customers',
    title: 'Customers',
    icon: <People set="curved" className="remix-icon" />,
    children: [
      {
        id: 'customers-all',
        title: 'All Customers',
        navLink: '/customers/all',
        roles: allExcept([systemRoles.SystemAdministrator, systemRoles.Marketing])
      },
      {
        id: 'onboarding-requests',
        title: 'Track Onboarding',
        navLink: '/customers/onboarding-requests',
        roles: allExcept([systemRoles.Operations, systemRoles.Finance])
      },
      {
        id: 'cba-dump',
        title: 'Upload CBA Dump',
        navLink: '/cba-dump/new',
        roles: [systemRoles.SystemAdministrator, systemRoles.Operations]
      },
      {
        id: 'cba-dump-data',
        title: 'CBA Dump',
        navLink: '/cba-dump/all',
        roles: [systemRoles.SystemAdministrator, systemRoles.Operations]
      }
    ],
    roles: []
  },
  {
    id: 'compliance',
    title: 'Compliance',
    icon: <ShieldDone set="curved" className="remix-icon" />,
    children: [
      {
        id: 'risk-assessment',
        title: 'Risk Assessment',
        navLink: '/risk-assessment'
      },
      {
        id: 'kyc',
        title: 'Pending KYC',
        navLink: '/kyc'
      },
      {
        id: 'proof-of-address',
        title: 'Proof of Address',
        navLink: '/proof-of-address'
      },
      {
        id: 'bvn-checker',
        title: 'BVN Checker',
        navLink: '/bvn-checker'
      }
    ],
    roles: [systemRoles.Compliance, systemRoles.Audit]
  },
  {
    id: 'products',
    title: 'Products',
    icon: <Work set="curved" className="remix-icon" />,
    children: [
      {
        id: 'products-savings',
        title: 'Savings',
        navLink: '/products/savings'
      },
      {
        id: 'products-investments',
        title: 'Investments',
        navLink: '/products/investments'
      }
    ],
    roles: [systemRoles.Finance, systemRoles.Operations]
  },
  {
    id: 'user-access-control',
    title: 'User Access Control',
    icon: <Category set="curved" className="remix-icon" />,
    children: [
      {
        id: 'system-users',
        title: 'System Users',
        navLink: '/system-users/all',
        roles: [systemRoles.SystemAdministrator]
      },
      {
        id: 'create-user',
        title: 'Create User',
        navLink: '/system-users/new',
        roles: [systemRoles.SystemAdministrator]
      },
      {
        id: 'account-officer',
        title: 'Account officers',
        navLink: '/account-officers'
      },
      {
        id: 'audit-log',
        title: 'Audit Log',
        navLink: '/audit-log',
        roles: [systemRoles.Audit, systemRoles.Compliance]
      }
    ],
    roles: []
  },
  {
    id: 'affiliate-program',
    title: 'Affiliate Program',
    icon: <Activity set="curved" className="remix-icon" />,
    children: [
      {
        id: 'referrals',
        title: 'Referrals',
        navLink: '/referrals'
      },
      {
        id: 'leaderboard',
        title: 'Leaderboard',
        navLink: '/leaderboard'
      },
      {
        id: 'campaigns',
        title: 'Campaigns',
        navLink: '/campaigns'
      }
    ],
    roles: allExcept([
      systemRoles.Operations,
      systemRoles.Compliance,
      systemRoles.Audit,
      systemRoles.Finance
    ])
  },
  {
    id: 'communication-program',
    title: 'Communication',
    icon: <Notification set="curved" className="remix-icon" />,
    children: [
      {
        id: 'notice-board',
        title: 'Notice Board',
        navLink: '/notice-board'
      }
    ],
    roles: [systemRoles.SystemAdministrator, systemRoles.Marketing]
  },
  {
    id: 'feedback',
    title: 'Feedback',
    icon: <Message set="curved" className="remix-icon" />,
    children: [
      {
        id: 'support-requests',
        title: 'Support Requests',
        navLink: '/support-requests'
      }
    ],
    roles: [systemRoles.Sales, systemRoles.Compliance, systemRoles.Audit]
  },
  {
    header: 'Reports',
    roles: allExcept([systemRoles.SystemAdministrator, systemRoles.Marketing])
  },
  {
    id: 'transactions',
    title: 'Transactions',
    icon: <Chart set="curved" className="remix-icon" />,
    children: [
      {
        id: 'transactions-general',
        title: 'General',
        navLink: '/reports/transactions',
        roles: [
          systemRoles.Sales,
          systemRoles.Compliance,
          systemRoles.Audit,
          systemRoles.Finance,
          systemRoles.Operations
        ]
      },

      {
        id: 'inflow-report',
        title: 'Inflows',
        children: [
          {
            id: 'wallet-inflow-report',
            title: 'Inflow',
            navLink: `/reports/inflow-report`
          },
          {
            id: 'card-transactions',
            title: 'Card Transactions',
            navLink: `/reports/card-transactions`
          },
          {
            id: 'virtual-account-transactions',
            title: 'Virtual Transactions',
            navLink: `/reports/virtual-account-transactions`
          }
        ],
        roles: [
          systemRoles.Operations,
          systemRoles.Finance,
          systemRoles.Compliance,
          systemRoles.Audit
        ]
      },
      {
        id: 'transactions-saving',
        title: 'Savings',
        // icon: <SaveOutlined />,
        children: [
          {
            id: 'transactions-star-account',
            title: 'Star Account',
            exact: true,
            navLink: `/reports/transactions?category=S&productId=${savingInvestmentProduct['Star Account']}`
          },
          {
            id: 'transactions-goals',
            title: 'Goals',
            navLink: `/reports/transactions?category=S&productId=${savingInvestmentProduct['Goals']}`
          },
          {
            id: 'transactions-vault',
            title: 'Vault',
            navLink: `/reports/transactions?category=S&productId=${savingInvestmentProduct['Vault']}`
          }
        ]
      },
      {
        id: 'transactions-investment',
        title: 'Investment',
        // icon: <DollarCircleOutlined />,
        children: [
          {
            id: 'transactions-money-market-fund',
            title: 'Money Market Fund',
            navLink: `/reports/transactions?category=I&productId=${savingInvestmentProduct['Money Market Fund']}`
          },
          {
            id: 'transactions-hyis-classic',
            title: 'HYIS Classic',
            navLink: `/reports/transactions?category=I&productId=${savingInvestmentProduct['HYIS Classic']}`
          },
          {
            id: 'transactions-hyis-premium',
            title: 'HYIS Premium',
            navLink: `/reports/transactions?category=I&productId=${savingInvestmentProduct['HYIS Premium']}`
          },
          {
            id: 'transactions-treasury-bills',
            title: 'Treasury Bills',
            navLink: `/reports/transactions?category=I&productType=${savingInvestmentProduct['Treasury Bills']}`
          }
        ]
      },
      {
        id: 'withdrawal-report',
        title: 'Withdrawal Report',
        // icon: <DollarCircleOutlined />,
        children: [
          {
            id: 'withdrawals',
            title: 'Withdrawals',
            navLink: `/reports/withdrawal-report`
          },
          {
            id: 'pending-withdrawal',
            title: 'Pending Withdrawal',
            navLink: `/reports/pending-withdrawal`
          }
        ],
        roles: [
          systemRoles.Operations,
          systemRoles.Finance,
          systemRoles.Compliance,
          systemRoles.Audit
        ]
      },

      {
        id: 'transaction-analysis',
        title: 'Payment Schedule',
        navLink: `/reports/transaction-analysis`,
        roles: [systemRoles.Operations, systemRoles.Finance, systemRoles.Audit]
      }
    ],
    roles: allExcept([systemRoles.SystemAdministrator, systemRoles.Marketing])
  },

  {
    id: 'subscriptions',
    title: 'Subscriptions',
    icon: <Chart set="curved" className="remix-icon" />,
    children: [
      {
        id: 'subscriptions-general',
        title: 'All Subscriptions',
        navLink: '/reports/subscriptions/all'
      },
      {
        id: 'subscriptions-savings',
        title: 'Star Account',
        exact: true,
        navLink: `/reports/subscriptions/savings`
      },
      {
        id: 'subscriptions-goals',
        title: 'Goals',
        navLink: `/reports/subscriptions/goals`,
        roles: []
      },
      {
        id: 'subscriptions-fixed-funds-upfront',
        title: 'Vault - Upfront',
        navLink: `/reports/subscriptions/fixed-funds?type=${fixedFundsInterestTypes.upfront}`
      },
      {
        id: 'subscriptions-fixed-funds-maturity',
        title: 'Vault - Maturity',
        navLink: `/reports/subscriptions/fixed-funds?type=${fixedFundsInterestTypes.maturity}`
      }
      // {
      //   id: 'subscriptions-money-market-fund',
      //   title: 'Money Market Fund',
      //   navLink: `/reports/subscriptions/money-market-fund`
      // },
      // {
      //   id: 'subscriptions-hyis-classic',
      //   title: 'HYIS Classic',
      //   navLink: `/reports/subscriptions/hyis-classic`
      // },
      // {
      //   id: 'subscriptions-hyis-premium',
      //   title: 'HYIS Premium',
      //   navLink: `/reports/subscriptions/hyis-premium`
      // }
    ],
    roles: allExcept([systemRoles.SystemAdministrator, systemRoles.Marketing])
  }
];

export default pages;
