import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/auth/actions';

import LeftContent from './leftContent';

export default function Login() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.login);

  const onFinish = (values) => {
    dispatch(login(values));
  };

  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <LeftContent />

      <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col xxl={11} xl={15} lg={20} md={20} sm={24} className="hp-px-sm-8 hp-pt-24 hp-pb-48">
            <h1 className="hp-mb-sm-0">Login</h1>
            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
              Welcome back, please login to your account.
            </p>
            {auth.succeeded ? (
              <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-success-1"></p>
            ) : (
              <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-danger-3">{auth?.message}</p>
            )}

            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{ username: '', password: '' }}
              onFinish={onFinish}
              className="hp-mt-sm-16 hp-mt-32">
              <Form.Item
                label="Username :"
                name="username"
                rules={[{ required: true }]}
                className="hp-mb-16">
                <Input id="error" />
              </Form.Item>

              <Form.Item
                label="Password :"
                name="password"
                rules={[{ required: true }]}
                className="hp-mb-8">
                <Input.Password id="warning2" />
              </Form.Item>

              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button block type="primary" htmlType="submit" loading={auth.loading}>
                  Sign in
                </Button>
              </Form.Item>
            </Form>

            <Col className="hp-other-links hp-mt-24">
              <Link to={'/'} className="hp-text-color-black-80 hp-text-color-dark-40">
                Privacy Policy
              </Link>
              <Link to={'/'} className="hp-text-color-black-80 hp-text-color-dark-40">
                Term of use
              </Link>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
