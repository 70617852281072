import * as investmentsProductFilterTypes from '../types/investmentsProductFilterTypes';

export const INITIAL_STATE = {
  Type: '',
  Currency: '',
  Status: ''
};

const investmentsProductFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case investmentsProductFilterTypes.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case investmentsProductFilterTypes.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default investmentsProductFilterReducer;
