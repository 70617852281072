import { combineReducers } from 'redux';

import appReducer from './app';
import customiseReducer from './customise/customiseReducer';
import authReducer from './auth/reducers';
import customerReducer from './customer/reducers';
import filtersReducer from './filters/reducers';
import complianceReducer from './compliance/reducers';
import kycReducer from './kyc/reducers';
import accountOfficerReducer from './account-officer/reducers';
import financialsReducer from './financials/reducers';
import systemUserReducer from './system-users/reducers';
import savingsReducer from './savings/reducers';
import investmentsReducer from './investments/reducers';
import reportsReducer from './reports/reducers';
import auditReducer from './audit/reducers/auditReducer';
import referralsReducer from './referrals/reducers';
import noticeBoardReducer from './communication/reducers';
import feedbackReducer from './feedback/reducers';

const rootReducer = combineReducers({
  app: appReducer,
  audit: auditReducer,
  customise: customiseReducer,
  auth: authReducer,
  customer: customerReducer,
  filters: filtersReducer,
  compliance: complianceReducer,
  kyc: kycReducer,
  accountOfficer: accountOfficerReducer,
  financials: financialsReducer,
  systemUser: systemUserReducer,
  savings: savingsReducer,
  investments: investmentsReducer,
  reports: reportsReducer,
  referrals: referralsReducer,
  noticeBoard: noticeBoardReducer,
  feedback: feedbackReducer
});

export default rootReducer;
