import * as onboardingTypes from '../types/onboardingTypes';

const INITIAL_STATE = {
  loading: false,

  data: [],
  succeeded: false,
  message: '',
  errors: null,
  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false
};

const onboardingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case onboardingTypes.GET_ALL:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case onboardingTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default onboardingReducer;
