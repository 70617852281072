import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Layout, Row, Col } from 'antd';

import Sidebar from './components/menu/Sidebar';
import MenuHeader from './components/header';
import MenuFooter from './components/footer';
import ScrollTop from './components/scroll-to-top';
import ShowExpirationModal from './components/content/modal/extend-expires-min';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { extendToken } from '../redux/auth/actions/refreshTokenActions';
import { tokenHelper } from '../helpers';
import ChangePassword from './components/content/modal/change-password-modal';
const { Content } = Layout;

export default function VerticalLayout(props) {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  // Redux
  const customise = useSelector((state) => state.customise);
  const [isActive, setIsActive] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const dispatch = useDispatch();
  const getRefreshToken = tokenHelper.getRefreshToken();
  const getToken = tokenHelper.getToken();
  useEffect(() => {
    let timer = setTimeout(() => {
      if (isActive) {
        dispatch(extendToken({ token: getToken, refreshToken: getRefreshToken }));
        setIsActive(false);
      }
    }, 240000);
    return () => {
      clearTimeout(timer);
    };
  }, [isActive, dispatch, getToken, getRefreshToken]);

  useEffect(() => {
    const handleMouseMove = () => setIsActive(true);
    const handleKeyDown = () => setIsActive(true);
    if (!isShown) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isShown]);

  return (
    <Layout className="hp-app-layout">
      <Sidebar visible={visible} setVisible={setVisible} />

      <Layout className="hp-bg-color-dark-90">
        <MenuHeader setVisible={setVisible} />

        <Content className="hp-content-main">
          <Row justify="center">
            {customise.contentWidth === 'full' && <Col span={24}>{children}</Col>}

            {customise.contentWidth === 'boxed' && (
              <Col xxl={20} xl={22} span={24}>
                {children}
              </Col>
            )}
          </Row>
        </Content>

        <MenuFooter />
        {!isActive && <ShowExpirationModal setIsShown={setIsShown} />}
        <ChangePassword />
      </Layout>

      <ScrollTop />
    </Layout>
  );
}
