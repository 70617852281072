import * as modalTypes from '../types/deleteSystemUserTypes';

const INITIAL_STATE = {
    open: false,
    data: null
};

const deleteSystemUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case modalTypes.OPEN_DELETE_MODAL:
            return {
                open: true,
                data: action.payload
            };
        case modalTypes.CLOSE_DELETE_MODAL:
            return {
                open: false,
                data: null
            };
        default:
            return state;
    }
};

export default deleteSystemUserReducer;
