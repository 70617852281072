const themeConfig = {
  version: "2.2",
  theme: "light", // light - dark
  contentWidth: "full", // full - boxed
  sidebarCollapsed: false,
  sidebarCollapseButton: true,
  layout: "VerticalLayout", // VerticalLayout - HorizontalLayout
  navigationFull: false,
  navigationBg: false,
  direction: "ltr", // ltr - rtl
  language: "en", // tr - en - es - fr
};

export default themeConfig;
