import * as pendingWithdrawalType from '../types/pendingWithdrawalTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
  stats: null,

  page: 0,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  succeeded: false,
  message: '',
  errors: null
};

const pendingWithdrawalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pendingWithdrawalType.LOADING:
      return {
        ...state,
        loading: true
      };
    case pendingWithdrawalType.GET_PENDING_WITHDRAWAL:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    default:
      return state;
  }
};

export default pendingWithdrawalReducer;
