import { combineReducers } from 'redux';
import transactionHistoryReducer from './transactionHistoryReducer';
import transactionHistoryDetailsReducer from './transactionHistoryDetailsReducer';
import investmentsReducer from './investmentsReducer';
import updateTrasuryVolumeReducer from './updateTreasuryVolumeReducer';

export default combineReducers({
  transactionHistory: transactionHistoryReducer,
  transactionHistoryDetails: transactionHistoryDetailsReducer,
  investments: investmentsReducer,
  treasuryVolume: updateTrasuryVolumeReducer
});
