import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Menu } from 'antd';

import navigation from '../../../../navigation/vertical';
import { getUserRole } from '../../../../helpers';
import { systemRoles } from '../../../../configs/models/system-roles';

const { SubMenu } = Menu;

export default function MenuItem(props) {
  const { onClose } = props;
  const role = getUserRole();
  const hasReadAccess =
    role === systemRoles.BusinessAdministrator || role === systemRoles.AllAccessViewer;

  // Redux
  const customise = useSelector((state) => state.customise);

  // Location
  const location = useLocation();
  const { pathname, search } = location;

  const splitLocation = pathname.split('/');

  // Menu
  const splitLocationUrl = search
    ? splitLocation[splitLocation.length - 2] +
      '/' +
      splitLocation[splitLocation.length - 1] +
      search
    : splitLocation[splitLocation.length - 2] + '/' + splitLocation[splitLocation.length - 1];

  const menuItem = navigation.map((item, index) => {
    if (item.header && (hasReadAccess || item.roles?.includes(role) || !item.roles?.length)) {
      return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
    }

    if (item.children && (hasReadAccess || item.roles?.includes(role) || !item.roles?.length)) {
      return (
        <SubMenu key={item.id} icon={item.icon} title={item.title}>
          {item.children.map((childrens, index) => {
            if (!childrens.children) {
              const childrenNavLink = childrens.navLink.split('/');

              if (hasReadAccess || childrens.roles?.includes(role) || !childrens.roles?.length) {
                return (
                  // Level 2
                  <Menu.Item
                    key={childrens.id}
                    className={
                      splitLocationUrl ===
                      childrenNavLink[childrenNavLink.length - 2] +
                        '/' +
                        childrenNavLink[childrenNavLink.length - 1]
                        ? 'ant-menu-item-selected'
                        : 'ant-menu-item-selected-in-active'
                    }
                    onClick={onClose}>
                    <Link to={childrens.navLink}>{childrens.title}</Link>
                  </Menu.Item>
                );
              } else return null;
            } else {
              return (
                // Level 3
                (hasReadAccess || childrens.roles?.includes(role) || !childrens.roles?.length) && (
                  <>
                    <SubMenu key={childrens.id} title={childrens.title}>
                      {childrens.children.map((childItem, index) => {
                        const childrenItemLink = childItem.navLink.split('/');

                        if (
                          hasReadAccess ||
                          childItem.roles?.includes(role) ||
                          !childItem.roles?.length
                        ) {
                          return (
                            <Menu.Item
                              key={childItem.id}
                              className={
                                splitLocationUrl ===
                                childrenItemLink[childrenItemLink.length - 2] +
                                  '/' +
                                  childrenItemLink[childrenItemLink.length - 1]
                                  ? 'ant-menu-item-selected'
                                  : 'ant-menu-item-selected-in-active'
                              }
                              onClick={onClose}>
                              <Link to={childItem.navLink}>{childItem.title}</Link>
                            </Menu.Item>
                          );
                        } else return null;
                      })}
                    </SubMenu>
                  </>
                )
              );
            }
          })}
        </SubMenu>
      );
    } else if (hasReadAccess || item.roles?.includes(role) || !item.roles?.length) {
      const itemNavLink = item.navLink.split('/');

      return (
        // Level 1
        <Menu.Item
          key={item.id}
          icon={item.icon}
          onClick={onClose}
          className={
            splitLocation[splitLocation.length - 2] +
              '/' +
              splitLocation[splitLocation.length - 1] ===
            itemNavLink[itemNavLink.length - 2] + '/' + itemNavLink[itemNavLink.length - 1]
              ? 'ant-menu-item-selected'
              : 'ant-menu-item-selected-in-active'
          }>
          <Link to={item.navLink}>{item.title}</Link>
        </Menu.Item>
      );
    } else {
      return null;
    }
  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null,
        splitLocation[splitLocation.length - 2]
      ]}
      theme={customise.theme === 'light' ? 'light' : 'dark'}>
      {menuItem}
    </Menu>
  );
}
