import * as userTypes from '../types/userTypes';
import { userStorage } from '../../../helpers/userStorage';
import { tokenHelper } from '../../../helpers/tokenHelper';

export const setUserData = (response) => {
  const currentToken = tokenHelper.getToken();
  const currentRefreshToken = tokenHelper.getRefreshToken();
  const currentUser = userStorage.getUserData();

  if (currentUser && currentToken && currentRefreshToken) {
    return {
      type: userTypes.SET_USER_DATA,
      payload: currentUser
    };
  } else {
    tokenHelper.setToken(response?.data?.token);
    response && delete response?.data?.token;
    tokenHelper.setRefreshToken(response?.data?.refreshToken);
    userStorage.setUserData(response?.data);

    return {
      type: userTypes.SET_USER_DATA,
      payload: response?.data
    };
  }
};
