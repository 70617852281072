import { pagination } from '../../../configs/models/pagination';
import * as accountOfficerFilterTypes from '../types/accountOfficerFilterTypes';

export const INITIAL_STATE = {
  Search: '',
  Status: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const accountOfficerFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case accountOfficerFilterTypes.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case accountOfficerFilterTypes.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default accountOfficerFilterReducer;
