import * as cardTypes from '../types/cardTypes';

const INITIAL_STATE = {
  card: {
    open: false,
    data: null
  },
  account: {
    open: false,
    data: null
  },
  cardStatus: {
    open: false,
    data: null
  }
};

const cardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cardTypes.OPEN_CARD_MODAL:
      return {
        ...state,
        card: {
          open: true,
          data: action.payload
        }
      };
    case cardTypes.CLOSE_CARD_MODAL:
      return {
        ...state,
        card: {
          open: false,
          data: null
        }
      };
    case cardTypes.OPEN_ACCOUNT_MODAL:
      return {
        ...state,
        account: {
          open: true,
          data: action.payload
        }
      };
    case cardTypes.CLOSE_ACCOUNT_MODAL:
      return {
        ...state,
        account: {
          open: false,
          data: null
        }
      };
    case cardTypes.OPEN_CHANGE_CARD_MODAL:
      return {
        ...state,
        cardStatus: {
          open: true,
          data: action.payload
        }
      };
    case cardTypes.CLOSE_CHANGE_CARD_MODAL:
      return {
        ...state,
        cardStatus: {
          open: false,
          data: null
        }
      };
    default:
      return state;
  }
};

export default cardReducer;
