import * as consolidatedAccountsTypes from '../types/consolidatedAccountsTypes';

const INITIAL_STATE = {
  loading: false,
  userId: null,

  data: {
    wallet: null,
    account: null,
    balances: []
  },
  succeeded: false,
  message: '',
  errors: null
};

const consolidatedAccountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case consolidatedAccountsTypes.LOADING:
      return {
        ...state,
        loading: true
      };
    case consolidatedAccountsTypes.GET_CONSOLIDATED_ACCOUNTS:
      return {
        ...state,
        loading: false,
        userId: action.userId,
        ...action.payload
      };
    default:
      return state;
  }
};

export default consolidatedAccountsReducer;
