import * as modalTypes from '../types/walletTypes';

const INITIAL_STATE = {
  freeze: {
    open: false,
    data: null
  },
  deactivate: {
    open: false,
    data: null
  },
  delete: {
    open: false,
    data: null
  }
};

const walletReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.OPEN_FREEZE_WALLET_MODAL:
      return {
        ...state,
        freeze: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_FREEZE_WALLET_MODAL:
      return {
        ...state,
        freeze: {
          open: false,
          data: null
        }
      };
    case modalTypes.OPEN_DEACTIVATE_WALLET_MODAL:
      return {
        ...state,
        deactivate: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_DEACTIVATE_WALLET_MODAL:
      return {
        ...state,
        deactivate: {
          open: false,
          data: null
        }
      };
    case modalTypes.OPEN_DELETE_WALLET_MODAL:
      return {
        ...state,
        delete: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_DELETE_WALLET_MODAL:
      return {
        ...state,
        delete: {
          open: false,
          data: null
        }
      };
    default:
      return state;
  }
};

export default walletReducer;
