import * as modalTypes from '../types/investmentTransactionsTypes';

const INITIAL_STATE = {
  list: {
    open: false,
    data: null
  },
  details: {
    open: false,
    data: null
  }
};

const investmentTransactionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.OPEN_MODAL:
      return {
        ...state,
        list: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_MODAL:
      return {
        ...state,
        list: {
          open: false,
          data: null
        }
      };
    case modalTypes.OPEN_DETAILS_MODAL:
      return {
        ...state,
        details: {
          open: true,
          data: action.payload
        }
      };
    case modalTypes.CLOSE_DETAILS_MODAL:
      return {
        ...state,
        details: {
          open: false,
          data: null
        }
      };
    default:
      return state;
  }
};

export default investmentTransactionsReducer;
