import { statusLabel } from '../configs/models/documents';
import { isValidUrl } from './stringHelper';

export const isImageLink = (url) => {
  let types = ['jpg', 'jpeg', 'png'];
  let urlList = url.split('.');

  let mimeType = urlList[urlList.length - 1];
  if (types.includes(mimeType)) {
    return true;
  }
  return false;
};

export const isPDFLink = (url) => {
  let types = ['pdf'];
  let urlList = url.split('.');

  let mimeType = urlList[urlList.length - 1];
  if (types.includes(mimeType)) {
    return true;
  }
  return false;
};

export const getStatusText = (status) => {
  return statusLabel[status];
};

export const createLink = (url) => {
  let anchor = document.createElement('a');
  anchor.setAttribute('href', url);
  anchor.setAttribute('target', '_blank');

  return anchor;
};

export const pluralize = (items, text, prefix = 's') => {
  if (items) {
    return items.length > 1 ? `${items.length} ${text}${prefix}` : `${items.length} ${text}`;
  }
};

export const currencyFormat = (amount, currency = 'NGN') => {
  if (amount) {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency }).format(amount);
  }
};

export const getCurrentPath = (location) => {
  const pathname = location?.pathname;
  const paths = pathname?.split('/');
  const currentPath = paths[paths.length - 1];

  return currentPath;
};

export const toCsvText = (data, prop = '') => {
  if (!prop) return '';

  if (data && data.length) {
    return data
      .map((object) => {
        if (object.hasOwnProperty(prop)) {
          return object[prop];
        } else {
          return Object.values(object)[0];
        }
      })
      .join(', ');
  }
};

export const initiateDownload = (url) => {
  if (url && isValidUrl(url)) {
    let dl = document.createElement('a');
    dl.setAttribute('href', url);
    dl.setAttribute('download', url);
    dl.click();
  }
};
