import { pagination } from '../../../configs/models/pagination';
import * as auditLogFilterTypes from '../types/auditLogFilterTypes';

export const INITIAL_STATE = {
  Search: '',
  UserRoles: '',
  UserId: 0,
  // ActivityType: 0,
  ActivityDetails: '',
  DateFrom: '',
  DateTo: '',
  Export: '',
  // DataSource: '',
  PageNumber: 1,
  PageSize: pagination().defaultPageSize
};

const auditLogFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case auditLogFilterTypes.ON_CHANGE:
      return {
        ...state,
        ...action.payload
      };
    case auditLogFilterTypes.CUSTOMER_AUDIT:
      return {
        ...state,
        ...action.payload
      };
    case auditLogFilterTypes.ON_RESET:
      return {
        ...state,
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default auditLogFilterReducer;
